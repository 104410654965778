.news-ticker {
    background-color: #333;
    color: #fff;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    padding: 10px 0;
    position: relative;
    direction: rtl; /* RTL text direction */
}

.news-ticker-content {
    display: inline-block;
    animation-name: scroll-rtl;
    animation-timing-function: linear;
    animation-iteration-count: infinite; /* Infinite loop */
    animation-duration: 60s; /* Adjust duration to control speed */
    padding-left: 100%; /* Add padding to ensure smooth loop in RTL */
}

.news-item {
    display: inline-block;
    margin-right: 50px; /* Space between news items in RTL */
    white-space: nowrap;
}

@keyframes scroll-rtl {
    0% {
        transform: translateX(-100%); /* Start off-screen on the left */
    }
    100% {
        transform: translateX(100%); /* End off-screen on the right */
    }
}
