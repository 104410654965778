.about-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    direction: rtl;
    text-align: right;
}

h1, h2, h3, h4 {
    color: #333;
}

.mission-vision, .values, .staff, .contact-info {
    margin-bottom: 40px;
}

.staff-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.staff-card {
    flex: 1 1 calc(33% - 40px);
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.staff-card h3 {
    margin-top: 0;
}

.contact-info p {
    font-size: 16px;
    line-height: 1.5;
}
