.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.center {
    margin: auto;
    width: 80%;
    padding: 10px;
}

.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    font-size: 64px;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
}

.resultContainer,
.historyItem {
    flex-direction: row;
    display: flex;
    width: 400px;
    align-items: center;
    justify-content: space-between;
}

.historyContainer {
    flex-direction: column;
    display: flex;
    height: 200px;
    align-items: center;
    flex-grow: 5;
    justify-content: flex-start;
}

.buttonContainer {
    display: flex;
    padding-top: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 260px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.inputContainer {
    padding: 16px;
}

.inputContainer > .errorLabel {
    color: red;
    font-size: 12px;
}

.inputBox {
    width: 100%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    background: #f1f1f1;
    word-wrap: anywhere;
}

input[type=text]:focus, input[type=password]:focus {
    background-color: #ddd;
    outline: none;
}


/* This will style any <img> element in .parent div */
.qrcode img {
    height: 40%;
    width: 40%;
}

.logincontainer {
    width: 100px;
}

.flex {
    display: flex;
}

.sameRow {
    height: 1px;
}

.normal {
    height: 5px;

}

tfoot {
    text-align: right;
}

.container .box {
    width: 100%;
    display: table;
}

.container .box .box-row {
    display: table-row;
}

.container .box .box-cell {
    display: table-cell;
    padding: 5px;
}

.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

.h-custom {
    height: calc(100% - 73px);
}

@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
}

.react-calendar {
    width: 100% !important;
}

.calender-body {
    background-color: #e7f3f8 !important;
}

.react-calendar__tile--active {
    background-color: #fbbc13 !important;
}

.today {
    border: 2px solid #1f1b13 !important;
}

.react-calendar_month-viewdays_day {
    margin: 12px !important;
}

.react-calendar__tile--active:enabled:hover {
    background-color: #fbbc13 !important;
}

.react-calendar__month-view__days__day--weekend {
    color: #1f1b13 !important;
}

.react-calendar__tile--now {
    background-color: transparent !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
}

.present-marker {
    color: #1a8825;
}

.absent-marker {
    color: #ba1a1a !important;
}

.on-leave-marker {
    color: #ba1a1a !important;
}

.tile-day {
    border: 1px solid #b2a9b5 !important;
    height: 90px !important;
    border-radius: 5px;
    margin-bottom: 5px !important;
}


.attendance-not-marked abbr {
    border: 1px dashed #ba1a1a;
    color: #ba1a1a !important;
    border-radius: 50%;
    padding: 5px;
}

.card {
    /* min-height: 50vh; */
    height: fit-content;
    min-width: 33rem;
    max-width: 100rem;
    background: rgba(147, 208, 119, 1);
    margin: 0 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.head {
    position: relative;
    max-height: fit-content;
    min-width: 33rem;
    width: 100%;
    background: rgb(109, 157, 87);
    padding: 1rem;
}


/* Input box and button */
.add {
    text-align: right;
    padding: 5% 5% 5% 6%;
    /* margin: 10%; */
}

input {
    margin-right: 1%;
}

#title {
    width: 100%;
    font-size: small;
}

#description {
    font-size: small;
    width: 100%;
}

/* style accordion */
/*.notes {
    overflow-y: scroll;
    text-align: right;
    height: 200px;
    width: 500px
}*/

/* style for accordion items */
/*.notes-item {
    display: flex;
    z-HomePage: 100;
    padding: 2%;
    !* border-bottom: 1px solid #4e7b48; *!
    font-size: large;
    margin: 2%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    text-align: right;
}*/

/* style for accordion items */
.accordion-item {
    border-bottom: 1px solid #ddd;
    font-size: larger;
}

.studentExtraInfo {
    border: 3px solid #ddd;
}

.note_text {
    font-size: large;
    max-width: 250px;
    word-wrap: break-word;
    text-align: right;
}



@media screen and (max-height:700px) {
    .scroll
    {

        overflow: auto;
    }
}


.rounded-t-5 {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
}

@media (max-width: 550px) {
    .rounded-tr-lg-0 {
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0rem;
    }
}
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.styled-table {
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

}
.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}
.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

/* Chat.css */
.chat-window {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.resizer {
    cursor: se-resize;
    width: 15px;
    height: 15px;
    background: gray;
    position: absolute;
    right: 0;
    bottom: 0;
}

/* MessageList.css */
.message-list {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
}
/* Message.css */
.message {
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 10px;
    max-width: 70%;
    word-wrap: break-word;
}

.message.family {
    background-color: lightgreen;
    margin-left: auto;
    text-align: left;
}

.message.management {
    background-color: #e5e5ea;
    margin-right: auto;
    text-align: right;
}

.message-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 0.8em;
    color: #555;
}

.sender {
    font-weight: bold;
}

.timestamp {
    font-style: italic;
}

/* SendMessageForm.css */
.send-message-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.send-message-form textarea {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none; /* Prevent resizing */
    flex: 1;
}

.send-message-form button {
    align-self: flex-end;
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.send-message-form button:hover {
    background-color: #0056b3;
}
/* PaymentForm.css */
.payment-form-dialog {
    width: 600px;
    max-width: 100%;
    border-radius: 8px;
    overflow: hidden;
    padding: 0;
}

.dialog-title {
    background-color: #f5f5f5;
    padding: 16px;
    border-bottom: 1px solid #ddd;
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
}

.dialog-content {
    padding: 24px;
    background-color: #fff;
}

.notes {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #eee;
}

.notes-item {
    margin-bottom: 10px;
    padding: 10px;
    background-color: #e0f7fa;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dialog-actions {
    padding: 16px;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: flex-end;
    background-color: #f5f5f5;
}

.dialog-actions button {
    margin-left: 8px;
}

.dialog-content h3 {
    margin: 8px 0;
    color: #333;
}

.dialog-content h4 {
    margin: 4px 0;
    color: #555;
}

.dialog-content .notes-item h4 {
    margin: 0;
}
/* AbsentDays.css */

.react-calendar {
    max-width: 300px;
    background-color: #f0f0f0;
}

.react-calendar__tile--active {
    background-color: #F44336FF; /* Light red */
}

.absent-day.react-calendar__tile--active {
    background-color: #ff6666; /* Dark red */
}
/* Add this CSS to your styles if using CSS or styled-components */
.MuiTableContainer-root {
    scrollbar-width: thin; /* Firefox */
}

.MuiTableContainer-root::-webkit-scrollbar {
    width: 8px; /* Chrome, Safari and Opera */
}

.MuiTableContainer-root::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar */
}

.MuiTableContainer-root::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color when hovered */
}

.stickyHeader {
    background-color: #f5f5f5; /* Adjust as needed */
    position: sticky;
    top: 0;
    z-index: 10; /* Ensures header is above other content */
}