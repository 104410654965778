.id-card {
    position: relative;
    background-color: #0B3857; /* Set midnight blue background color */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
    height: 350px;
    padding: 20px;
    font-size: 14px;
    text-align: center;
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Space between children */
    margin-bottom: 20px; /* Space between cards */
    font-family: 'Times New Roman', Times, serif; /* Font style */
}

.id-card-container {
    display: flex;
    flex-direction: column;
    width: 500px; /* Card width */
    height: 700px;
    text-align: right;
}

.header {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #0B3857;
    padding: 5px;
    width: 80%; /* Make the header shorter than the card */
    border-radius: 0 0 10px 10px; /* Round only the bottom corners */
    z-index: 1;
    display: flex;
    justify-content: space-between; /* Space between logo and school info */
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    width: 75px; /* Adjust size if needed */
    height: 75px; /* Adjust size if needed */
    margin-right: 10px;
    border-radius: 50%; /* Make the logo image rounded */
}

.school-info {
    text-align: center; /* Align school info to the right */
}

.school-info h1 {
    font-size: 24px; /* Make the school name bigger */
    color: white;
    margin-right: 50px;
    text-align: center;
}

.id-card-text {
    text-align: right;
    font-size: 12px; /* Adjust ID Student Card size */
    color: white;
    margin-top: 15px; /* Optional spacing */

}

.content-row {
    display: flex;
    padding: 10px;
    margin-top: 30px; /* Space for the header */
    border-radius: 50px;
    z-index: 0;
    position: relative; /* Set position for absolute footer */
    background-color: white;
}

.profile {
    margin-top: 20px;

    margin-right: 20px;
}

.profile img {
    width: 150px;
    height: 150px;
    border-radius: 10px; /* Rounded corners for the image */
    object-fit: cover;
    margin-top: 20px;
    margin-bottom: 5px;
}

.student-info {
    text-align: right; /* Align text to the right */
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    direction: rtl; /* Set direction to RTL for Arabic */
    padding-right: 50px;
}

.student-info p {
    margin-bottom: -1px; /* Adjusted for 2px spacing */
}

.student-info strong {
    color: black;
}

.footer {
    position: absolute;
    bottom: 0; /* Position it at the bottom of the content-row */
    left: 50%;
    transform: translateX(-50%);
    background-color: #0B3857;
    padding: 5px; /* Reduced padding for a smaller footer */
    width: 80%; /* Keep the footer width the same */
    border-radius: 30px; /* Rounded corners for the footer */
    z-index: 1;
    font-size: 12px; /* Reduced font size for the footer text */
    text-align: center;
}

.footer a {
    text-decoration: none;
    color: white;
}

.footer p {
    text-decoration: none;
    color: white;
}

.footer a:hover {
    text-decoration: underline;
}

/* Additional styling for the back */
.back h2 {
    margin: 20px 0;
}

.back p {
    font-size: 14px;
}
.back {
    height: 350px;
    align-content: center;
}

