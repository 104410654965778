/* Gallery.css */

.gallery-container {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
    height: 100%;
}

.gallery-wrapper {
    display: inline-block;
    white-space: nowrap;
    animation: scroll 30s linear infinite;
}

.gallery-item {
    display: inline-block;
    margin-right: 16px; /* Adjust spacing between images */
}

@keyframes scroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
